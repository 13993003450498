@import '_variables.scss';
@import '_mixins.scss';
@import 'widgets/_circleimage.scss';
@import 'widgets/_loader.scss';
@import 'widgets/_modal.scss';
@import 'widgets/_checked-status.scss';

body {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

// Ginger bold
.box-header,
.card-header p,
.navbar
.nav-link,
.header-user-profile h4 {
  font-family: "Ginger", Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.disabled {
  color: $o-border-color !important;
  cursor: no-drop !important;
}

// anchor without link 
a:not([href]):not([tabindex]) {
  cursor: pointer;
}

// buttons
.btn {
  display: block;
  height: $o-input-height;
  line-height: $o-input-height;
  margin: 0 auto;
  padding: 0 1rem;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: $o-border-radius;

  &.focus,
  &:focus {
    box-shadow: none;
  }
}

.btn-primary {
  max-width: 15rem;
  border-color: transparent;
  background-color: $o-blue-light;
  @include mediaq (sm) {
    max-width: 17rem;
  }

  &:not(:disabled):not(.disabled) {
    color: #fff;

    &.active,
    &:active {
      border-color: $o-blue-dark;
      background-color: $o-blue-dark;

      &:focus {
        box-shadow: none;
      }
    }
  }

  &.focus,
  &:focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled,
  &[disabled]:hover {
    border-color: $o-blue-light;
    background-color: $o-blue-light;
  }

  &:hover {
    border-color: $o-blue-dark;
    background-color: $o-blue-dark;
  }
}

// .btn-secondary {
.btn {
  &.focus,
  &:focus,
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
}

.btn-flat {
  color: #fff;
  height: 45px;
  line-height: 45px;
  border: 0;
  width: 100%;
  max-width: 22rem;
  font-size: 16px;
  border-radius: $o-border-radius-small;
  background-color: $o-blue-light;

  &:hover {
    background-color: $o-gray-dark;
  }
}

// background colors
.bg-gray-light {
  background-color: $o-gray-light;
}

.bg-gray-dark {
  color: #fff;
  background-color: $o-gray-dark;

  .btn-primary {
    color: $o-gray-light;
    background-color: #fff;
  }
}

.bg-red {
  background-color: $o-red;
}

.bg-green {
  background-color: $o-green;
}

.bg-orange {
  background-color: $o-orange;
}

.bg-blue-light {
  background-color: $o-blue-light;
}

// header for blocks and modules
.box-header {
  color: #fff;
  min-height: 48px;
  line-height: 48px;
  margin-bottom: 0;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  border-radius: $o-border-radius $o-border-radius 0 0;
  background-color: $o-blue-light;
  @include mediaq(md) {
    font-size: 22px;
  }
}

.header-bottom {
  color: $o-gray-dark;
  height: 40px;
  font-size: 10px;
  border-top: 0;
  font-weight: bold;
  text-transform: uppercase;
  background-color: $o-box-bg-secondary;
  @include mediaq(md) {
    height: 50px;
  }

  & > div:first-child {
    line-height: 40px;
    @include mediaq(md) {
      line-height: 50px;
    }
  }

  &.bb-rs {
    border-radius: 0 0 $o-border-radius $o-border-radius;
  }
}

.box-body {
  border: 1px solid $o-border-color;
  border-top: 0;
  border-radius: 0 0 $o-border-radius $o-border-radius;
  background-color: $o-box-bg-primary;
  // overflow: hidden;
}

// badge pill 
.badge {
  min-height: 19px;
  // line-height: 19px;
  padding: .37rem .7rem;
  margin-right: 5px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  vertical-align: middle;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  // white-space: normal;
  @include mediaq(md) {
    font-size: 14px;
    min-height: 26px;
    // line-height: 26px;
  }
}

.badge-info {
  background-color: $o-blue-light;
}

// border radius wrapper
.br-wrap {
  overflow: hidden;
  border-radius: $o-border-radius;
  backface-visibility: hidden;
}

// inputs
.form-control {
  text-align: center;
  border-radius: $o-border-radius;
}

// "square" buttons. eg: Dashboard, select job role
.box-link {
  cursor: pointer;

  &:hover {
    .box {
      @include mediaq(lg) {
        opacity: .6;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .box-link {
    &:hover {
      .box {
        opacity: 1;
      }
    }
  }
}

// used at job roles and secondary options
.no-gutters {
  .col-md-2:last-child {
    border-right: 1px solid $o-border-color;
  }

  .box-link {
    box-shadow: -1px 1px 0 0 $o-border-color;

    &.col-md.odd:last-child {
      border-right: 1px solid $o-border-color;
      @include mediaq(md) {
        border-right: 0;
      }
    }

    &:nth-child(6n) {
      border-right: 0;
    }
  }
}

.box {
  color: $o-black;
  display: block;
  padding: 1.6rem 0;
  text-align: center;

  span {
    font-size: 2rem;
  }

  h6 {
    font-size: 0.8rem;
  }
}

// make text unselectable
.no-highlight {
  user-select: none;
}


// when a list doesn't contain any data - eg: manage jobs, timesheets, chat
.empty-card {
  color: $o-orange;
  border-top: 1px solid $o-border-color;
}
