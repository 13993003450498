ckeditor#long-term {
  .ck.ck-toolbar {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    border-radius: 15px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 1px solid #cacaca;
    border-top: none;
    background: #fff;
  }

  .ck.ck-reset.ck-editor {
    display: flex;
    flex-direction: column-reverse;

    .ck-content {
      min-height: 300px;
      padding: 0 15px;
    }

    .ck-toolbar {
      padding: 10px 15px;
    }

    &.fullscreen {
      position: fixed !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
      display: flex;
      flex-direction: column;
      background: #fff;

      .ck-editor__editable_inline {
        border: none;
        height: 100%;
      }

      .ck-editor__main {
        flex-grow: 1;
        overflow-y: auto;
        border: 1px solid #cacaca;
        border-top: none;
        border-radius: 15px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        & > .ck-content {
          border: 0;
        }
      }

      .ck-sticky-panel__content_sticky {
        top: 0 !important;
        width: 100% !important;
      }

      .ck.ck-toolbar {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        border-radius: 15px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #cacaca;
        background: #fff;
      }

      .fullscreen-toggle {
        background-color: #ccc;
      }
    }
  }

  .ck.ck-editor__editable_inline {
    border-radius: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #cacaca;
    border-bottom: none;
  }

  // remove toolbar scroll events+blur events, for displaying at the top level;
  .ck.ck-sticky-panel {
    .ck-sticky-panel__content_sticky {
      position: static;
      width: initial !important;
      margin-left: initial !important;
    }

    .ck-sticky-panel__placeholder {
      display: none !important;
    }
  }
}

ckeditor#long-term.empty {
  .ck-content {
    position: relative;

    &:before {
      content: 'Start typing...';
      opacity: 0.3;
      position: absolute;
      left: 15px;
      top: 18px;
      font-size: 18px;
    }
  }
}
