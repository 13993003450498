
body .ng-select.cl {
  font-size: 14px;

  &:not(.searchable) {
    .ng-input {
      display: none;
    }
  }

  .ng-select-container.ng-has-value {
    .ng-placeholder {
      display: none;
    }
  }

  &.h-45 {
    .ng-select-container {
      height: 45px;
      padding: 13px;
    }
  }

  .ng-select-container {
    height: 50px;
    border-radius: 5px;
    border: 1px solid #CACACA;
    padding: 15px;


    .ng-value-label {
      line-height: 16px;
    }

    .ng-arrow-wrapper {
      cursor: default;
      position: relative;

      &:before,
      &:after {
        content: '';
        border: solid #979797;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 2px;
        vertical-align: middle;
        position: absolute;
      }

      &:before {
        top: 2px;
        transform: rotate(-135deg);
      }

      &:after {
        bottom: 2px;
        transform: rotate(45deg);
      }
    }
  }


}

body .ng-dropdown-panel {
  box-sizing: border-box;
  position: absolute;
  opacity: 0;
  width: 100%;
  z-index: 1050;
  top: 0;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  padding-bottom: 0;

  .ng-dropdown-header {
    border: 1px solid transparent;
  }

  .selected-entity {
    pointer-events: none;
    border-bottom: 1px solid #F2F2F2;
    padding: 15px;
    margin-bottom: 10px;
  }

  &.h-45 {
    .ng-option {
      height: 45px;
      padding: 13px;
    }
  }

  &.ng-select-top {
    box-shadow: 0 -3px 5px -3px rgba(0, 0, 0, 0.2), 0px -5px 10px 1px rgba(0, 0, 0, 0.14), 0px -5px 14px 2px rgba(0, 0, 0, 0.12);
    border: 1px solid #CACACA;
    margin-bottom: -1px;

    .ng-dropdown-panel-items {
      border-radius: 5px;
    }

    .ng-option {
      padding: 13px;
    }
  }

  &.option-bold {
    .ng-option {
      font-weight: bold;
    }
  }

  .ng-option {
    padding: 15px 25px;
    color: $o-gray-dark;

    &.ng-option-marked {
      background: #20C4FF !important;
      color: #fff !important;
      font-weight: bold;
    }

    &.ng-option-selected {
      font-weight: bold;
      background: #D1F3FF;
    }

    &:last-of-type {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}