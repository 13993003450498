
.select-modal {
  transform: unset !important;
  margin: 0;

  &.modal-full {
    height: 100%;
    max-height: 100%;
    width: 100%;

    .modal-content {
      max-height: 100%;
      height: 100%;
      overflow: auto;
    }
  }

  &.full-width {
    max-width: 100%;
  }


  .modal-content {
    background: #fff;
    margin: 0;
    border-radius: 0;
    padding: 15px;
  }

  .modal-header {
    font-size: 18px;
    line-height: 22px;
    padding: 0;
    text-align: center;
    font-weight: bold;
    margin-bottom: 25px;

    .m-icon {
      color: #979797;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .clear {
      color: #979797;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-weight: 400;
      font-size: 16px;
    }
  }

  .modal-footer {
    margin: auto -15px -15px -15px;
    background: #4D4D4C;
    padding: 15px 25px;

    .btn {
      height: 40px;
      border-radius: 5px;
      line-height: 40px;
      margin: 0;
      min-width: 100%;
    }
  }
}
