@import './_variables.scss';

.book-daily-supply {
  dp-day-calendar {
    display: block;
    margin: 0 auto;

    .dp-calendar-wrapper {
      text-align: center;
    }

    .dp-calendar-wrapper,
    .dp-calendar-nav-container {
      border: none !important;
    }

    .dp-day-calendar-container {
      padding-bottom: 10px;
      border: 1px solid $o-border-color;
      border-top: 0;
    }

    &.dp-material {
      .dp-calendar-nav-container {
        max-width: 250px;
        margin: 0 auto;
        padding: 10px 0;
        height: 50px;
        @include mediaq(md) {
          max-width: 300px;
        }
      }

      .dp-current-day {
        border: none !important;
      }

      .dp-calendar-day {
        background-color: transparent;
      }

      .dp-selected {
        color: #fff;
        background: $o-gray-dark;

        &:hover {
          color: #fff;
          background: $o-gray-dark;
        }
      }
    }

    &.dp-material .dp-calendar-weekday,
    .dp-calendar-day {
      width: 34px;
      height: 34px;
      line-height: 34px;
      margin: 1px;
      padding: 0;
      text-align: center;
      @include mediaq(md) {
        width: 44px;
        height: 44px;
        line-height: 44px;
      }
    }

    .dp-selected {
      &.full {
        color: #fff;
        background: $o-green;
      }

      &.am, &.pm {
        color: #fff;
        position: relative;
        z-index: 0;
        background: none;
        overflow: hidden;
      }

      &.am, &.pm {
        color: #fff;
        background: $o-border-color;

        &:after {
          z-index: -1;
          content: "";
          position: absolute;
          width: 50%;
          height: 100%;
          top: 0;
          background: $o-green;
          left: 0;
          transform: rotate(45deg);
          transform-origin: 100% 50%;
        }

        &:hover {
          &:after {
            background-color: $o-gray-dark;
          }
        }
      }

      &.pm:after {
        transform: rotate(-135deg);
      }
    }

    .dp-weekdays {
      background-color: $o-box-bg-secondary;
    }
  }

  dp-time-select {
    .dp-time-select-controls {
      background: none;
    }

    .dp-time-select-control-up,
    .dp-time-select-control-down {
      padding: 0;
    }

    .dp-time-select-control-down:before {
      top: -4px;
    }
  }

  dp-calendar-nav {
    .dp-nav-header {
      left: 50%;
      font-size: 1rem;
      transform: translate(-50%, -50%);
    }

    .dp-nav-btns-container {
      left: 5px;

      .dp-calendar-nav-container-right {
        float: right;
      }
    }
  }
}


.book-long-term,
.book-daily-supply {
  dp-time-select {
    .dp-time-select-control-up:before {
      top: 0;
    }
  }
}

.select-role-container {
  .box-body {
    overflow: hidden;
  }
}
