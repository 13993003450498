@import './_variables.scss';

app-time-select.booking {
  padding-left: 10px;

  button::before {
    color: $o-blue-light;
  }

  .dp-time-select-control-meridiem {
    opacity: 0.7;

    button {
      visibility: hidden;
    }
  }

  $c-black: #000000;
  $c-white: #FFFFFF;
  $c-primary: #106CC8;
  $c-light-gray: #E0E0E0;

  $basic-height: 30px;

  @mixin arrow($deg) {
    &::before {
      position: relative;
      content: '';
      display: inline-block;
      height: 8px;
      width: 8px;
      vertical-align: baseline;
      border-style: solid;
      border-width: 2px 2px 0 0;
      transform: rotate($deg);
    }
  }


  display: inline-block;

  .dp-time-select-controls {
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: normal;
    background: $c-white;
  }

  .dp-time-select-control {
    display: inline-block;
    width: 35px;
    margin: 0 auto;
    vertical-align: middle;
    font-size: inherit;
    letter-spacing: 1px;
  }

  .dp-time-select-control-up, .dp-time-select-control-down {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    margin: 3px auto;
    cursor: pointer;

    @include arrow(0deg);
  }

  .dp-time-select-control-up::before {
    transform: rotate(-45deg);
    top: 4px;
  }

  .dp-time-select-control-down::before {
    transform: rotate(135deg);
  }

  .dp-time-select-separator {
    width: 5px;
  }

  &.dp-material {
    .dp-time-select-control-up, .dp-time-select-control-down {
      box-sizing: border-box;
      background: transparent;
      border: none;
      outline: none;
      border-radius: 50%;

      &::before {
        left: 0;
      }

      &:hover {
        background: $c-light-gray;
      }
    }
  }
}
