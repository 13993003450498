.password-toggle {
  position: absolute;
  right: 0;
  padding: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #91989F;
}

.with-toggle {
  position: relative;

  input {
    padding-right: 40px !important;
  }
}



