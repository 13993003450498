@import './_variables.scss';

.book-long-term,
.add-content {
  dp-day-calendar {
    display: block;
    margin: 0 auto;
    background-color: $o-box-bg-primary;

    .dp-calendar-wrapper {
      text-align: center;
    }

    .dp-calendar-wrapper,
    .dp-calendar-nav-container {
      border: none !important;
    }

    .dp-day-calendar-container {
      padding-bottom: 10px;
    }

    &.dp-material {
      .dp-calendar-nav-container {
        max-width: 250px;
        margin: 0 auto;
        padding: 10px 0;
        height: 50px;
        @include mediaq(md) {
          max-width: 300px;
        }
      }

      .dp-current-day {
        border: none !important;
      }

      .dp-selected {
        color: #fff;
        background: transparent;

        &.first-day {
          &:before {
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
          }
        }

        &.last-day {
          &:before {
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
          }
        }
      }

      .dp-selected,
      .inner-day {
        position: relative;
        z-index: 0;

        &::before {
          position: absolute;
          content: '';
          top: 2px;
          left: -1px;
          right: -1px;
          bottom: 3px;
          z-index: -1;
        }

        &:hover {
          font-weight: bold;

          &::before {
            top: -1px;
            bottom: -1px;
          }
        }
      }

      .inner-day {
        &::before {
          background: $o-gray-light;
        }
      }

      .dp-selected {
        &::before {
          background: $o-blue-light;
        }
      }
    }

    &.dp-material
    .dp-calendar-weekday,
    .dp-calendar-day {
      width: 34px;
      height: 34px;
      line-height: 34px;
      margin: 1px;
      padding: 0;
      text-align: center;
      @include mediaq(md) {
        width: 44px;
        height: 44px;
        line-height: 44px;
      }
    }

    .dp-weekdays {
      background-color: rgba($color: $o-gray-light, $alpha: .2);
    }
  }

  dp-calendar-nav {
    .dp-nav-header {
      left: 50%;
      font-size: 1rem;
      transform: translate(-50%, -50%);
    }

    .dp-nav-btns-container {
      left: 5px;

      .dp-calendar-nav-container-right {
        float: right;
      }
    }
  }

  // .add-role-specifics {
  //     .box-header {
  //         & + .box-body {
  //             border-radius: 0;
  //         }
  //     }
  // }
}
