@import '../_variables.scss';
@import '../_mixins.scss';

// modal styles
.modal-dialog {
  vertical-align: middle;

  &.exit-booking {
    .modal-content {
      background-color: transparent;
    }
  }

  @include mediaq(sm) {
    &.cv-modal-dialog {
      width: 80vw;
      max-width: 742px;
    }
    &.course-overview {
      width: 80vw;
      max-width: 1110px;
    }
  }
}

.modal-content {
  border: 0;
  border-radius: $o-border-radius;
  background-color: $o-gray-light;

  .box-header {
    padding: .7rem 3rem;
    line-height: 1.7rem;
  }
}

.modal-header {
  display: block;
  position: relative;
  border: 0;

  .close {
    position: absolute;
    top: 21px;
    right: 20px;
    width: 33px;
    height: 33px;
    color: #fff;
    font-size: 1.9rem;
    text-shadow: none;
    font-weight: normal;
    border: 2px solid #fff;
    border-radius: 50%;
    padding: 0;
    outline: none;
    opacity: 1;

    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):hover {
      color: $o-blue-dark;
      border-color: $o-blue-dark;
    }
  }

  h4 {
    font-size: 22px;
  }
}

.modal-body {
  padding-top: 0;
  padding-bottom: 0;

  p {
    font-size: .7rem;
    text-align: center;
    padding: 0 11%;
  }

  .textarea-wrapper {
    padding: 1rem;
    background-color: #fff;
    border-radius: $o-border-radius;
    border: 1px solid $o-border-color;
  }

  textarea {
    width: 100%;
    max-height: 70vh;
    border: 0;
    outline: 0;
    background-color: #fff;
  }

  li {
    list-style-position: inside;
  }
}

.modal-footer {
  border: 0;
  display: block;
  @include mediaq(md) {
    display: flex;
  }

  .btn {
    display: block;
    color: #fff;
    border: 0;
    margin: 1rem auto 0;
    @include mediaq(md) {
      margin: 0 .25rem;
    }
    box-shadow: none;
  }

  .btn-success {
    background-color: $o-blue-light;

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &:hover {
      box-shadow: none;
      background-color: $o-blue-dark;
    }
  }

  .btn-danger {
    background-color: $o-orange;

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &:hover {
      box-shadow: none;
      background-color: #b76607;
    }
  }

  &:focus {
    box-shadow: none;
  }
}

.modal {
  &.show {
    .modal-dialog {
      transform: translate(0, 15vh);
      @include mediaq(md) {
        transform: translate(0, 7vh);
      }
    }
  }
}

.modal-backdrop {
  &.show {
    opacity: .7;
  }
}

// delete job on manage jobs page
.delete-job {
  .box-body {
    padding: 40px;

    .modal-body {
      max-width: 19rem;
      margin: 0 auto 15px;
      font-size: 24px;
      font-family: "Ginger", Arial, Helvetica, sans-serif;
    }
  }

  .modal-footer {
    .btn {
      width: 100%;
      @include mediaq(md) {
        &:first-child {
          margin-right: 15px;
        }
        &:last-child {
          margin-left: 15px;
        }
      }
    }
  }
}
